const roleName = {
  2: 'ministry', // 管理员角色（部级）
  3: 'ministry', // 管理员角色（部级）
  4: 'expert', // 专家审核角色
  5: 'province', // 省级审核角色
  6: 'country', // 市县上报角色
  10: 'remoteSense', // 遥感中心角色
  11: 'mingchengBranch', //名城分院角色
}
export const formatRole = (role) => {
  let id = role.id
  return id ? { ...role, key: roleName[role.id] } : {}
}