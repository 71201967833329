import Vue from 'vue'
import VueRouter from 'vue-router'
import systemPage from '../views/systemPage.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/systemPage',
    name: 'systemPage',
    component: () => import('../views/systemPage.vue')
  },
  {
    path: '/systemPage/planningMap',
    name: 'systemPagePlanningMap',
    component: () => import('../views/PlanningPrepare/planningMap.vue')
  },
  {
    path: '/home/ministry/mapPage',
    name: 'mapPage',
    component: () => import('../views/Home/ministry/mapPage.vue')
  },
  {
    path: '/404',
    name: '404Page',
    component: () => import('../views/404Page.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/loginPage.vue')
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/indexPage.vue')
  },
  {
    path: '/oauth2',
    name: 'oauth2',
    component: () => import('../views/oauth2Page.vue')
  },
  {
    path: '/passport',
    name: 'passport',
    component: () => import('../views/passport.vue')
  },
  {
    path: '/ResourcesCensus/cityAndCounty/indexPage',
    name: 'indexPage',
    component: () => import('../views/ResourcesCensus/cityAndCounty/indexPage.vue')
  },
  {
    path: '/ResourcesCensus/province/indexPage',
    name: 'provinceIndexPage',
    component: () => import('../views/ResourcesCensus/province/indexPage.vue')
  },
  {
    path: '/ResourcesCensus/ministry/indexPage',
    name: 'ministryIndexPage',
    component: () => import('../views/ResourcesCensus/ministry/indexPage.vue')
  },
  {
    path: '/ResourcesCensus/expert/indexPage',
    name: 'expertIndexPage',
    component: () => import('../views/ResourcesCensus/expert/indexPage.vue')
  },
  {
    path: '/PlanningPrepare',
    name: 'PlanningPrepare',
    component: () => import('../views/PlanningPrepare/index.vue')
  },
  {
    path: '/PlanningPrepare/planningMap',
    name: 'planningMap',
    component: () => import('../views/PlanningPrepare/planningMap.vue')
  },
  {
    path: '/baseInfoTable',
    name: 'baseInfoTable',
    component: () => import('../views/ResourcesCensus/cityAndCounty/page/page/baseInfoTable.vue')
  },
  {
    path: '/evaluateSystem',
    name: 'evaluateSystem',
    component: () => import('../views/ResourcesCensus/cityAndCounty/page/page/evaluateSystem.vue')
  },
  {
    path: '/applyTable',
    name: 'applyTable',
    component: () => import('../views/ResourcesCensus/cityAndCounty/page/page/applyTable.vue')
  },
  {
    path: '/personalCenter/mineInfo',
    name: 'mineInfo',
    component: () => import('../views/personalCenter/mineInfo.vue')
  },
  {
    path: '/personalCenter/expertInfo',
    name: 'expertInfo',
    component: () => import('../views/personalCenter/expertInfo.vue')
  },
  {
    path: '/personalCenter/userManagement',
    name: 'userManagement',
    component: () => import('../views/personalCenter/userManagement.vue')
  },
  {
    path: '/personalCenter/changePassword',
    name: 'changePassword',
    component: () => import('../views/personalCenter/changePassword.vue')
  },
]

const router = new VueRouter({
  routes
})

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default router