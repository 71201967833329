import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


Vue.config.productionTip = false

import '@/assets/reset.css'
import '@/assets/svg/index'

import utils from '@/utils/index.js'
import api from '@/api/index.js'
Vue.prototype.$utils = utils
Vue.prototype.$apis = api

import '@/assets/font/font.css'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/iconfont/iconfont.css'


import SvgIcon from '@/components/svgIcon'
Vue.component('svg-icon', SvgIcon)

// 引入 element-ui
import {
  Select,
  Option,
  Input,
  InputNumber,
  Pagination,
  Message,
  MessageBox,
  Loading,
  Menu,
  MenuItem,
  MenuItemGroup,
  Submenu,
  Form,
  FormItem,
  Radio,
  Upload,
  Table,
  TableColumn,
  Dialog,
  Button,
  DatePicker,
  Switch,
  Divider,
  RadioGroup,
  Tooltip,
  Steps,
  Step,
  Tree,
  Cascader,
  ColorPicker,
  Progress,
  Notification,
  Breadcrumb,
  BreadcrumbItem,
  Popover,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem
} from 'element-ui';
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Submenu);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Radio);
Vue.use(Upload);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(DatePicker);
Vue.use(Switch);
Vue.use(Divider);
Vue.use(RadioGroup);
Vue.use(Tooltip);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Tree);
Vue.use(Cascader);
Vue.use(ColorPicker);
Vue.use(Progress);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Popover);
Vue.use(Checkbox);
Vue.use(CheckboxGroup)
Vue.use(Collapse);
Vue.use(CollapseItem)

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification

import moment from 'moment'
Vue.prototype.$moment = moment;

// 全局时间过滤器
Vue.filter('dateformat', (dataStr, pattern = 'yyyy-MM-DD HH:mm:ss') => {
  if (dataStr == '' || dataStr == null) {
    return '-'
  }
  return moment(dataStr).format(pattern)
})


// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    next('/login')
  } else {
    next()
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    this.$store.dispatch('initializeStore')
  },
}).$mount('#app')