<template>
    <span class="public-button" :class="getClass(type, disabled)" @click="handleClick">
        <span :class="icon" class="icon" v-if="icon != null"></span>{{ text }}
    </span>
</template>
<script>
export default {
    props: {
        disabled: { // 是否禁用
            default: false,
        },
        text: {
            default: '默认按钮'
        },
        type: {
            default: 1, // 1主要按钮，2次要按钮
        },
        icon: { // 图标icon
            default: null,
            type: String
        }
    },
    data() {
        return {
            loading: false, // 内置loading一秒
        }
    },
    methods: {
        handleClick() {
            if (this.disabled === true || this.loading === true) return
            this.loading = true
            this.$emit('click')
            setTimeout(() => {
                this.loading = false
            }, 1000);
        },
        getClass(type, disabled) {
            var className = ''
            if (type == 1) {
                className += 'primary'
            }
            if (type == 2) {
                className += 'secondary'
            }
            if (disabled === true || this.loading === true) {
                className += ' disabled'
            }
            return className
        }
    }
}
</script>
<style lang="less">
.public-button {
    display: inline-block;
    min-width: 64px;
    padding: 0 18px;
    border-radius: 10px;
    background-color: #8B7A9B;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    .icon {
        font-size: 16px;
        margin-right: 6px;
    }
    &:hover {
        background-color: #7E6F9D;
    }
    &.secondary {
        background-color: #fff;
        color: #8B7A9B;
        border: 1px solid #8B7A9B;
        &:hover {
            color: #7E6F9D;
            border-color: #7E6F9D;
        }
    }
    &.disabled {
        background-color: #E5E5E5 !important;
        color: #383838 !important;
        border: none !important;
        cursor: auto;
    }
    & + & {
        margin-left: 20px;
    }
}
</style>