import Vue from "vue";
import Vuex from "vuex";
import { formatRole } from "@/utils/formatRole";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    roleId: 0,
    unreadCount: null, // 未读消息的数量
    menu: [],
    myInfo: null, // 用户信息
    nowTime: null,
    checkBool: false,
    progressStatus: [
      { value: 0, label: "市县填报中" },
      { value: 1, label: "市县填报中" },
      { value: 2, label: "省级审核中" },
      { value: 3, label: "部级专家初审中" },
      { value: 4, label: "部级专家复核中" },
      { value: 5, label: "部级专家初审不通过" },
      { value: 6, label: "部级专家复核通过" },
      { value: 7, label: "部级专家复核不通过" }
    ],
    progressStatus2: [
      { value: 3, label: "部级专家初审中" },
      { value: 4, label: "部级专家复核中" },
      { value: 5, label: "部级专家初审不通过" },
      { value: 6, label: "部级专家复核通过" },
      { value: 7, label: "部级专家复核不通过" },
      { value: 8, label: "审定中" },
      { value: 9, label: "拟公布" },
      { value: 10, label: "部级审定不通过" }
    ],
    progressStatusCity: [
      { value: 0, label: "待提交/保存" },
      { value: 1, label: "退回" },
      { value: 14, label: "已备案" }
    ],
    showMask: false, // 是否显示遮罩层,
    // 规划审查的保护对象类型列表
    protectList: [
      {
        text: "名城",
        name: "CITY",
        type: 1,
        icon:'icon-a-ziyuan5'
      },
      {
        text: "名镇",
        name: "TOWN",
        type: 2,
        icon:'icon-a-ziyuan6'
      },
      {
        text: "名村",
        name: "VILLAGE",
        type: 3,
        icon: 'icon-a-ziyuan7'
      }
    ],
    protectInfo: {} // 判断进来的是名城 city、名镇town、名村village
  },
  getters: {
    myRole(state) {
      let { role = {} } = state.myInfo || {};
      return formatRole(role);
    },
    myRoleId(state, getters) {
      return getters.myRole.id;
    },
    protectText(state){
      return state.protectInfo.text
    },
    protectType(state){
      return state.protectInfo.type
    },
    protectName(state){
      return state.protectInfo.name
    }
  },
  mutations: {
    setMask(state, bool) {
      state.showMask = bool;
    },
    setMineInfo(state, data) {
      state.myInfo = data;
      state.roleId = data.role.id;
    },
    setNowTime(state, time) {
      state.nowTime = time;
    },
    checkForm(state) {
      state.checkBool = !this.state.checkBool;
    },
    getUnreadCount(state, params) {
      state.unreadCount = params;
    },
    setTypeInfo(state, name) {
      state.protectInfo = name;
      localStorage.setItem('protectInfo',JSON.stringify(name))
    }
  },
  actions: {
    // 初始化数据缓存，解决页面刷新vuex丢失问题
    initializeStore({commit}){
      const protectInfo = localStorage.getItem('protectInfo')
      if(protectInfo){
        commit('setTypeInfo',JSON.parse(protectInfo))
      }
    }
  },
  modules: {},
});
