<template>
    <el-popover placement="left" width="80" v-model="visible">
        <div class="img-box">
            <img src="../assets/image/code.jpg" alt="">
            <p>欢迎加入用户反馈群</p>
        </div>
        <div class="hoverBox" slot="reference">
            <svg-icon icon-file-name="kefu" :class="visible ? 'active' : ''"></svg-icon>
        </div>
    </el-popover>

</template>
<script>

export default {
    data() {
        return {
            visible: false
        }
    }
}
</script>
<style lang="less" scoped>
.hoverBox {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 5px #999;
    position: fixed;
    bottom: 100px;
    right: 60px;
    z-index: 9999;
    display: inline-block;
    cursor: pointer;
    svg {
        font-size: 24px;
        color: #333;
        margin: 8px;
        &:hover {
            color: rgb(255, 176, 52);
        }
        &.active {
            color: rgb(255, 176, 52);
        }
    }
}
.img-box {
    width: 160px;
    height: 160px;
    img {
        width: 120px;
        height: 120px;
        margin: 10px 15px;
    }
    >p {
        color: rgb(255, 176, 52);
        text-align: center;
        transform: scale(0.9, 1) translate(-6px, -10px);
    }
}
</style>